import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import Link from "next/link";
import Image from "next/image";
import Router, { useRouter } from "next/router";
import {
  byteSizeUnit,
  byteSizeValue,
  checkLogOrNot,
  useOnClickOutside,
} from "../../../common/function/function";
import logo from "../../../images/logo.png";
import Button from "../../../higher-order-components/button-hoc";
import Typography from "../../../higher-order-components/typography-hoc";
import SideModal from "../../../higher-order-components/model-hoc";
import { handleLogout } from "../../../redux-store/actions/auth";
import {
  getCookie,
  getLocal,
  removeLocal,
  setLocal,
} from "../../../common/function/storage";
import useWindowDimensions from "../../../common/function/hooks/use-window-dimensions";
import { handleGetAllPlans } from "../../../redux-store/actions/albums";
import { emailDashboard } from "../../../common/email";
import { RESET_PERFORMANCE_ANALYTICS } from "../../../redux-store/actions/dashboard";
// import { Link } from "react-scroll";
const Header = () => {
  const [menu, setMenu] = useState(false);
  const [progress, setProgress] = useState(0);
  const ref = useRef();
  const _TOKEN = getLocal("access_token");
  const _ROUTES = getLocal("routers");
  const _ACTIVE = getLocal("active");
  const location = useRouter();
  const { width } = useWindowDimensions();
  let isMobile = width <= 767;
  const [active, setActive] = useState();
  useOnClickOutside(ref, () => {
    setMenu(false);
  });

  const closeMenu = () => {
    setMenu(false);
  };
  let parseDetails = getLocal("login_user") ? getLocal("login_user") : null;
  const ALREADY_LOGGING = parseDetails;
  const dispatch = useDispatch();
  const _AUDIO_QUALITY_MODAL = useSelector(
    (state) => state?.common?.audioQualityModal
  );

  const ALREADY_LOGGING1 = useSelector((e) => e.memorize?.authStore?.fullname);
  const _GET_STORAGE_DATA = useSelector((e) => e?.common?.getStorageData);
  const _GET_AVAILABLE_CREDITS = useSelector((e) => e?.common?.getCreditsData);

  const logout = async () => {
    await dispatch(handleLogout(Router));
    removeLocal("active");
    setLocal("active", `/`);
    dispatch({
      type: "CURRENT_PLAY_DATA",
      payload: {
        isMultiplePlaying: false,
        currentPlayingIndex: 0,
        library: false,
        tracksData: [],
      },
    });
    dispatch({
      type: "ALBUMS_TYPE",
      payload: null,
    });
    dispatch({
      type: "SELECTED_PAYMENT_PLAN",
      payload: "Annually",
    });
    dispatch({ type: "[ALBUMS] GET_SUBSCRIPTION", payload: [] });
    dispatch(handleGetAllPlans(_ROUTES));
    dispatch({
      type: "SELECTED_PLAN_ID",
      payload: "",
    });
    dispatch({
      type: "DRAFT_API_PAYLOAD",
      payload: {},
    });
    dispatch({
      type: RESET_PERFORMANCE_ANALYTICS,
      payload: {},
    });
    setMenu(false);
  };

  useEffect(() => {
    setActive(_ACTIVE);
  }, [_ACTIVE]);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = menu ? "hidden" : "auto";
  }, [menu]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const commonClick = (routes) => {
    setProgress(progress + 80);
    Router.push(routes);
    removeLocal("active"), removeLocal("routers");
    setLocal("routers", `${routes}`);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const homeDiv = () => {
    if (_TOKEN) {
      return (
        <span onClick={() => checkLogOrNot(_TOKEN)} className="link-text">
          Home
        </span>
      );
    } else {
      return (
        <Link href="/" className="link-text">
          <a className={active === "/" ? "home-border-bottom" : ""}>Home</a>
        </Link>
      );
    }
  };

  const planDiv = () => {
    if (_TOKEN) {
      return (
        <span onClick={() => Router.push("/change-plan")} className="link-text">
          Plans
        </span>
      );
    } else {
      return (
        <Link href="/#pricing" className="link-text">
          <a className={active === "pricing" ? "home-border-bottom" : ""}>
            Plans
          </a>
        </Link>
      );
    }
  };

  const toolDiv = () => {
    if (_TOKEN) {
      return (
        <span onClick={() => checkLogOrNot(_TOKEN)} className="link-text">
          Tools
        </span>
      );
    } else {
      return (
        <Link href="/#tool" className="link-text">
          <a className={active === "tool" ? "home-border-bottom" : ""}>Tools</a>
        </Link>
      );
    }
  };

  const faqDiv = () => {
    if (_TOKEN) {
      return (
        <span
          onClick={() => checkLogOrNot(_TOKEN)}
          className="link-text"
          style={{ color: "#ffa443", position: "relative" }}
        >
          What's New? <i className="icon-sparkles-sharp-svgrepo-com"></i>
        </span>
      );
    } else {
      return (
        <>
          <Link href="/#relaunchfaq" className="link-text">
            <a
              className={active === "faq" ? "home-border-bottom" : ""}
              style={{ color: "#ffa443", position: "relative" }}
            >
              {" "}
              What's New?{" "}
              <i
                className="icon-sparkles-sharp-svgrepo-com"
                style={{ position: "absolute", width: "55px" }}
              ></i>
            </a>
          </Link>
        </>
      );
    }
  };

  const checkActive = (flag) => {
    removeLocal("active");
    setLocal("active", flag);
  };

  const commonAccountClick = () => {
    Router.push({
      pathname: "/account",
    });
    setMenu(!menu);
    dispatch({
      type: "CART_DROP_DOWN_TOGGLE",
      payload: false,
    });
    commonClick("/account");
  };

  return (
    <nav>
      <LoadingBar
        color="#dcba6c"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div
        ref={ref}
        className={`navbar-main ${scrollPosition ? "header_scroll" : ""}`}
        style={{
          zIndex: _AUDIO_QUALITY_MODAL ? "9" : isMobile ? "99999" : "9999",
        }}
      >
        <div className="container">
          <div className={`header_main_div`}>
            <div className="brand-logo">
              {isMobile && location?.asPath === "/" ? (
                location?.asPath === "/" ? (
                  <span
                    className="mob-header-title-text"
                    onClick={() => {
                      location?.asPath === "/" ? null : checkLogOrNot(_TOKEN);
                    }}
                  >
                    Home
                  </span>
                ) : null
              ) : (
                <div
                  onClick={() => {
                    !_TOKEN ? checkActive("/") : checkActive("/");
                    checkLogOrNot(_TOKEN);
                  }}
                >
                  <Image
                    src={logo}
                    alt="battlebards logo"
                    loading="eager"
                    priority
                  />
                </div>
              )}
            </div>

            <div className="header-user-dropdown header-bar-profile">
              <div className="profile">
                <div
                  className="user-profile-div"
                  onClick={() => setMenu(!menu)}
                >
                  <span className="user-icon">
                    <i className="icon-user-regular-icon"></i>
                  </span>{" "}
                  <i className="icon-chevron-down mob-hide"></i>
                  <Typography variant={"body1"} className={"header-name"}>
                    {ALREADY_LOGGING?.fullname}
                  </Typography>
                </div>
                {isMobile && menu && (
                  <SideModal
                    open={menu}
                    name={""}
                    maxWidth={340}
                    className="modal"
                    onClose={() => closeMenu()}
                    header={<h4></h4>}
                    body={
                      <div className="cursor-pointer">
                        {!_TOKEN &&
                        (_TOKEN === "undefined" || _TOKEN === null) ? (
                          <React.Fragment>
                            <div className="login-user-profile-div">
                              <i className="icon-user-regular-icon"></i>
                            </div>
                            <div className="mob-modal-login-btn-div">
                              <div className={""}>
                                <Button
                                  className={`box-shadow-btn-mob btn w-100`}
                                  icon={""}
                                  position={""}
                                  content={"Sign In"}
                                  onClick={() => {
                                    Router.push("/auth/signin");
                                  }}
                                ></Button>
                              </div>
                              <div className={""}>
                                <Button
                                  className={`btn w-100 mob-modal-cancel-btn`}
                                  position={""}
                                  content={"Sign Up"}
                                  onClick={() => {
                                    Router.push("/auth/signUp");
                                  }}
                                ></Button>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className="account-modal-main-div">
                              <div className="account-profile-div">
                                <div class="login-user-profile-div">
                                  <i class="icon-user-regular-icon"></i>
                                </div>
                                <h4 className="user-name-text">
                                  {ALREADY_LOGGING?.fullname
                                    ? ALREADY_LOGGING?.fullname
                                    : ALREADY_LOGGING1?.fullname}
                                </h4>
                                <p className="user-email-text">
                                  {ALREADY_LOGGING?.email
                                    ? ALREADY_LOGGING?.email
                                    : ALREADY_LOGGING1?.email}
                                </p>
                              </div>

                              <div className="flex-height">
                                <div className="storage-credits-main-div">
                                  <div className="storage-credits-card-main-div">
                                    <div
                                      className="storage-credits-card"
                                      onClick={() => {
                                        Router.push({
                                          pathname: "/store/library",
                                        });
                                        dispatch({
                                          type: "CART_DROP_DOWN_TOGGLE",
                                          payload: false,
                                        });
                                        commonClick("/store/library");
                                        dispatch({
                                          type: "MY_LIBRARY_TABS",
                                          payload: "Store",
                                        });
                                      }}
                                    >
                                      <i className="icon-info"></i>
                                      <p className="card-small-text">Storage</p>
                                      <p className="big-small-text">
                                        {byteSizeValue(
                                          _GET_STORAGE_DATA?.storage_space
                                            ?.total_storage <
                                            _GET_STORAGE_DATA?.storage_space
                                              ?.used_upload_storage
                                            ? _GET_STORAGE_DATA?.storage_space
                                                ?.total_storage
                                            : _GET_STORAGE_DATA?.storage_space
                                                ?.used_upload_storage
                                        )}{" "}
                                        /{" "}
                                        {byteSizeValue(
                                          _GET_STORAGE_DATA?.storage_space
                                            ?.total_storage
                                        )}{" "}
                                        {byteSizeUnit(
                                          _GET_STORAGE_DATA?.storage_space
                                            ?.total_storage
                                        )}
                                      </p>
                                    </div>
                                    <div className="storage-credits-card">
                                      <p className="card-small-text">Credits</p>
                                      <p className="big-small-text">
                                        {" "}
                                        $
                                        {_GET_AVAILABLE_CREDITS?.credit.toFixed(
                                          2
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="credits-btn-div m-t-25">
                                    <Button
                                      className={"box-shadow-btn-mob btn w-100"}
                                      icon={""}
                                      position={""}
                                      content={"Account"}
                                      onClick={() => {
                                        commonAccountClick();
                                      }}
                                    ></Button>
                                  </div>
                                  {/*       <div className="credits-btn-div m-t-25">
                                    <Button
                                      className={"box-shadow-btn-mob btn w-100"}
                                      icon={""}
                                      position={""}
                                      content={"Buy Credits"}
                                      onClick={() => {
                                        handle.commonAccountClick();
                                      }}
                                    ></Button>
                                  </div> */}
                                </div>
                              </div>
                              <div className="sign-out-link-div m-t-20">
                                <a
                                  className="sign-out-link"
                                  onClick={() => {
                                    logout();
                                  }}
                                >
                                  Sign Out
                                </a>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    }
                    footer={<React.Fragment> </React.Fragment>}
                  />
                )}
              </div>
            </div>

            <nav>
              <div className="navigations">
                <ul className="header-left-side-menu">
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      !_TOKEN ? checkActive("/") : checkActive("/");
                    }}
                  >
                    {homeDiv()}
                  </li>
                  <li>
                    <Button
                      className={"link-text cursor-pointer"}
                      icon={""}
                      position={""}
                      content={"Explore"}
                      onClick={() => commonClick("/store/explore")}
                    ></Button>
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      !_TOKEN ? checkActive("tool") : checkActive("/");
                    }}
                  >
                    {toolDiv()}
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      !_TOKEN ? checkActive("pricing") : checkActive("/");
                    }}
                  >
                    {planDiv()}
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      !_TOKEN ? checkActive("faq") : checkActive("/");
                    }}
                  >
                    {faqDiv()}
                  </li>
                </ul>

                <ul className="header-right-side-menu">
                  <li>
                    <Button
                      className={
                        location.pathname === "/about-us"
                          ? "home-border-bottom cursor-pointer"
                          : "cursor-pointer"
                      }
                      icon={""}
                      position={""}
                      content={"About"}
                      onClick={() => {
                        commonClick("/about-us");
                      }}
                    ></Button>
                  </li>

                  <li className="border-right-div cursor-pointer">
                    <Button
                      className={
                        location.pathname === "/contact"
                          ? "home-border-bottom cursor-pointer"
                          : "cursor-pointer"
                      }
                      icon={""}
                      position={""}
                      content={"Contact"}
                      onClick={() => {
                        commonClick("/contact");
                      }}
                    ></Button>
                  </li>
                  {!_TOKEN && (_TOKEN === "undefined" || _TOKEN === null) ? (
                    <React.Fragment>
                      <li className="">
                        <div className={""}>
                          <Button
                            className={`color-white cursor-pointer p-l-0`}
                            icon={""}
                            position={""}
                            content={"Sign In"}
                            onClick={() => Router.push("/auth/signin")}
                          ></Button>
                        </div>
                      </li>
                      <li className="">
                        <div className={""}>
                          <Button
                            className={`border-width-2 border-radius-5 color-white btn btn-outline-white cursor-pointer p-t-10 p-b-10 border-btn-hover`}
                            position={""}
                            content={"Sign Up"}
                            onClick={() => Router.push("/auth/signUp")}
                          ></Button>
                        </div>
                      </li>
                    </React.Fragment>
                  ) : (
                    <div className="header-user-dropdown">
                      <div className="profile">
                        <div className="user-profile-div ">
                          <span className="user-icon">
                            <i className="icon-user-regular-icon"></i>
                          </span>
                          <Typography
                            variant={"body1"}
                            className={"header-name"}
                            onClick={() => setMenu(!menu)}
                          >
                            {ALREADY_LOGGING?.fullname}
                            <i className="icon-chevron-down"></i>
                          </Typography>
                        </div>
                        {menu && (
                          <ul className="menu">
                            <li>
                              <Button
                                className={"link-text"}
                                content={"Account"}
                                onClick={() => {
                                  Router.push("/account");
                                }}
                              ></Button>
                            </li>
                            {ALREADY_LOGGING?.rolename === "admin" ? (
                              <>
                                <li>
                                  <Button
                                    className={"signin-text cursor-pointer"}
                                    icon={""}
                                    position={""}
                                    content={"Dashboard"}
                                    onClick={() => {
                                      Router.push("/dashboard");
                                    }}
                                  ></Button>
                                </li>
                                <li>
                                  <Button
                                    className={"signin-text cursor-pointer"}
                                    icon={""}
                                    position={""}
                                    content={"Support Tickets"}
                                    onClick={() => {
                                      Router.push("/support-tickets");
                                    }}
                                  ></Button>
                                </li>
                                <li>
                                  <Button
                                    className={"signin-text cursor-pointer"}
                                    icon={""}
                                    position={""}
                                    content={"Partner Dashboard"}
                                    onClick={() => {
                                      Router.push("/partner-dashboard");
                                    }}
                                  ></Button>
                                </li>
                              </>
                            ) : null}
                            <li>
                              <a
                                className="signin-text"
                                onClick={() => logout()}
                              >
                                Logout
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
